import React from 'react'
import styled from 'styled-components'
import { Seo } from '@/components/Seo'

export default function dummyTerms() {
  return (
    <DivWrap>
      <Seo title="テスト銀行Bank Pay取引規定" noIndex />
      <MainArea>
        <section>
          <H1Title>
            <span>テスト銀行Bank Pay取引規定</span>
          </H1Title>
          <ListMemo01>
            <li>
              <ListMark>1.</ListMark>
              （適用範囲）
            </li>
            <li>
              <ListMemo02>
                <li>
                  <ListNum>(1)</ListNum>
                  テスト銀行Bank
                  Pay取引規定（以下「本規定」といいます。）は、本規定の定めに従い、次の①から③までに定める者（以下「BP加盟店｣といいます｡）が行う商品の販売または役務の提供等（以下｢売買取引｣といいます｡）について利用者が当該BP加盟店に対して負担する債務（以下｢売買取引債務｣といいます｡）を、日本電子決済推進機構（以下「機構」といいます。）所定のBank
                  Pay決済アプリ（Bank
                  Pay取引契約（第4条第1項に定義します。）の締結にかかる機能を付与されているアプリであって、機構所定の利用者の端末にインストールされたものを意味し、以下「利用者アプリ」といいます。また、利用者アプリがインストールされた利用者の端末を、以下「利用者端末」といいます。）またはBank
                  Pay取引サイト（Bank
                  Pay取引契約の締結にかかる必要な機能を備えたウェブサイトをいいます。また、利用者アプリおよびBank
                  Pay取引サイトを総称して、以下「利用者アプリ等」といいます。）において利用登録された利用者名義の普通預金口座（以下｢登録預金口座｣といいます｡）から預金の引落し（総合口座取引規定、カードローン規定等に基づく当座貸越による引落しを含みます｡）によって支払う取引（以下｢Bank
                  Pay取引｣といいます｡）に関する利用者と当行との間の権利義務関係等を定めたものです。
                  <br />
                  利用者は、Bank
                  Pay取引を利用するにあたり、本規定の各条項を認識し了承のうえ、当行に対してBank
                  Pay取引の利用の申込みを行うものとし、利用者がBank
                  Pay取引を利用するにあたっては、利用者と当行との間において本規定が適用されるものとします（なお、本規定によるBank
                  Pay取引の利用にかかる契約を、以下「本契約」といいます。）。本規定に定めのない事項については、ＳＭＢＣダイレクト利用規定、キャッシュカード（普通預金・貯蓄預金）規定その他のBank
                  Pay取引の利用のためにまたはこれに付随しもしくは関連して利用者に適用される当行所定の規定（以下「本件関連規定」といいます。）の各条項に従います。
                </li>
                <li>
                  <ListMemo03>
                    <li>
                      <ListMark>①</ListMark>
                      機構所定のBank
                      Pay加盟店規約（以下｢規約｣といいます｡）を承認のうえ、機構にBP直接加盟店として登録され、機構の会員である一または複数の金融機関（以下｢BP加盟店銀行｣といいます｡）と規約所定のBank
                      Pay加盟店契約を締結した法人または個人（以下｢BP直接加盟店｣といいます｡）。但し、当該Bank
                      Pay加盟店契約の定めに基づき、登録預金口座について、BP直接加盟店に対する売買取引債務の支払いのためにBank
                      Pay取引を利用することができない場合があります。
                    </li>
                    <li>
                      <ListMark>②</ListMark>
                      規約を承認のうえ、BP直接加盟店と規約所定のBP間接加盟店契約を締結した法人または個人（以下「BP間接加盟店」といいます。）。但し、当該BP間接加盟店契約の定めに基づき、登録預金口座について、BP間接加盟店に対する売買取引債務の支払いのためにBank
                      Pay取引を利用することができない場合があります。
                    </li>
                    <li>
                      <ListMark>③</ListMark>
                      規約を承認のうえ機構にBP任意組合として登録されBP加盟店銀行とBank
                      Pay加盟店契約を締結した民法上の組合（以下「BP任意組合」といいます。）の組合員であり、規約を承認した法人または個人（以下「BP組合事業加盟店」といいます。）。但し、規約所定のBank
                      Pay組合契約の定めに基づき、登録預金口座について、BP組合事業加盟店に対する売買取引債務の支払いのためにBank
                      Pay取引を利用することができない場合があります。
                    </li>
                  </ListMemo03>
                </li>
                <li>
                  <ListNum>(2)</ListNum>
                  前項にかかわらず、BP加盟店によっては、利用者が利用者アプリを機構所定の方法で操作することにより、当該BP加盟店に対して負担する売買取引債務を、BP加盟店銀行が自らまたはBP直接加盟店もしくはBP任意組合を通じて立替払をする場合があります。この場合、利用者は、BP加盟店銀行に対し、当該立替払の費用にかかる補償債務を負担するものとします。利用者は、当該補償債務を、登録預金口座からの預金の引落しによって支払うものとし、これら一連の取引もBank
                  Pay取引に含まれるものとして、この規定（第4条を除く。）により取り扱うものとします。
                </li>
              </ListMemo02>
            </li>
            <li>
              <ListMark>1の2.</ListMark>
              （公金納付）
            </li>
            <li>
              <ListMemo02>
                <li>
                  <ListNum>(1)</ListNum>
                  機構所定のBank
                  Pay公的加盟機関規約（以下「BP公的加盟機関規約」といいます。）を承認のうえ、BP公的加盟機関規約所定のBP公的加盟機関として登録され、機構の会員である一または複数の金融機関（以下「BP加盟機関銀行」といいます。）とBP公的加盟機関規約所定のBank
                  Pay公的加盟機関契約を締結した法人（以下「BP公的加盟機関」といいます。）に対して、BP公的加盟機関規約に定めるBP公的加盟機関に対する公的債務（以下「公的債務」といいます。）の支払いのために、利用者が利用者アプリ等を機構所定の方法で操作した場合には、BP加盟機関銀行が当該公的債務の立替払を行うものとします。この場合、利用者は、BP加盟機関銀行に対して、当該立替払いの費用に係る補償債務を負担するものとします。利用者は、当該補償債務を、登録預金口座からの預金の引落しによって支払うものとし、これら一連の取引についてもBank
                  Pay取引に含まれるものとします。但し、当該Bank
                  Pay公的加盟機関契約の定めに基づき、登録預金口座がBP公的加盟機関で利用できない場合があります。
                </li>
                <li>
                  <ListNum>(2)</ListNum>
                  前項の定めに基づくBank
                  Pay取引については、「BP加盟店」を「BP公的加盟機関」、「BP加盟店銀行」を「BP加盟機関銀行」、「売買取引債務」を「公的債務」とそれぞれ読み替えた上で、この規定（第3条第4項第3号および第4条を除く。）を適用するものとします。
                </li>
              </ListMemo02>
            </li>
            <li>
              <ListMark>2.</ListMark>
              （利用登録の方法等）
            </li>
            <li>
              <ListMemo02>
                <li>
                  <ListNum>(1)</ListNum>
                  Bank
                  Pay取引において、利用者が当行に開設された利用者名義の普通預金口座を登録預金口座として利用登録するには、あらかじめ利用する利用者アプリを利用者の端末にインストールし、または利用者のパーソナルコンピュータその他の端末の利用によりBank
                  Pay取引サイト所定の決済方法の登録を目的としたウェブサイトにアクセスしたうえ、当行所定の方法により、口座情報その他の当行が利用者の本人確認のために必要と認める情報（以下「認証情報」といいます。）を入力し、登録預金口座を利用登録する必要があります。
                </li>
                <li>
                  <ListNum>(2)</ListNum>
                  登録預金口座の利用登録およびBank
                  Pay取引の利用は、利用者本人が自ら行うものとし、代理人その他の第三者による登録預金口座の利用登録およびBank
                  Pay取引の利用は認められません。
                </li>
                <li>
                  <ListNum>(3)</ListNum>
                  第1項の手続において入力された利用者の認証情報が、当行所定の本人確認のための情報と一致した場合には、当行は入力した者を利用者本人とみなし、利用者からの登録預金口座の利用登録を正当なものとして受け付けたうえ、その後の利用者によるBank
                  Pay取引の利用を正当なものとして取り扱います。なお、本項の規定により当行が利用者からの登録預金口座の利用登録を受け付けたことをもって、利用者と当行との間で本契約が成立するものとします。
                </li>
                <li>
                  <ListNum>(4)</ListNum>
                  当行が前項の規定により登録預金口座の利用登録を受け付けた場合には、認証情報につき偽造、変造、盗難、紛失、不正使用その他の事故があっても、それにより生じた損害については、第9条に定める場合を除き、当行は一切責任を負いません。
                </li>
                <li>
                  <ListNum>(5)</ListNum>
                  当行が前項の規定により登録預金口座の利用登録を受け付けた場合には、認証情報につき偽造、変造、盗難、紛失、不正使用その他の事故があっても、それにより生じた損害認証情報は、他人に使用されないよう管理してください。認証情報が、偽造、変造、盗難、紛失等により他人に使用されるおそれが生じた場合または他人に使用されたことを認知した場合には、すみやかに利用者本人から当行に通知し、Bank
                  Pay取引を不能とする措置や登録預金口座の停止等の不正利用の拡大防止措置を講じてください。利用者が本項の義務に違反したことにより生じた損害については、当行は一切責任を負いません。
                </li>
              </ListMemo02>
            </li>
            <li>
              <ListMark>3.</ListMark>
              （Bank Pay取引の方法等）
            </li>
            <li>
              <ListMemo02>
                <li>
                  <ListNum>(1)</ListNum>
                  Bank
                  Pay取引を利用する場合には、次の①から④までの方法のうち、BP加盟店が指定する方法によるものとします。なお、利用者は、いずれの方法による場合も、BP加盟店に設置された機構所定の端末（以下「加盟店端末」といいます。）または利用者端末もしくはBank
                  Pay取引サイトに表示される取引内容（売買取引債務の金額その他の売買取引にかかる事項をいいます。）を自ら確認するものとします。
                </li>
                <li>
                  <ListMemo03>
                    <li>
                      <ListMark>①</ListMark>
                      利用者端末に表示されたQRコード等（BP加盟店または利用者の特定に必要な情報その他のBank
                      Pay取引のために必要となる情報を記録したQRコード、バーコードその他の符号をいいます。以下同じ。）を、BP加盟店をして加盟店端末で読み取らせる方法
                    </li>
                    <li>
                      <ListMark>②</ListMark>
                      利用者端末で、加盟店端末に表示されたQRコード等を読み取る方法
                    </li>
                    <li>
                      <ListMark>③</ListMark>
                      BP加盟店に設置されているステッカーに表示されたQRコード等を利用者端末で読み取る方法（利用者端末において売買取引債務の金額の入力を要する場合があります。）
                    </li>
                    <li>
                      <ListMark>④</ListMark>
                      前各号に掲げるものの他、利用者アプリ等をBP加盟店所定の方法により操作することにより利用する方法
                    </li>
                  </ListMemo03>
                </li>
                <li>
                  <ListNum>(2)</ListNum>
                  利用者は、前項に規定する方法によりBank
                  Pay取引を利用する際に、利用者アプリ等において要求された場合には、利用者アプリ等にパスワード等（利用者アプリにおいてBank
                  Pay取引の実行等に必要とされる文字列その他の情報をいいます。以下同じ。）を入力する等、利用者アプリ等において要求される方法により、利用者本人によるBank
                  Pay取引の利用であることを確認するための手続（以下「本人認証」といいます。）を行うものとします。
                </li>
                <li>
                  <ListNum>(3)</ListNum>
                  預金の払戻しによる現金の取得を目的として、Bank
                  Pay取引を行うことはできません。
                </li>
                <li>
                  <ListNum>(4)</ListNum>
                  次の場合には、Bank Pay取引を行うことはできません。
                </li>
                <li>
                  <ListMemo03>
                    <li>
                      <ListMark>①</ListMark>
                      停電、通信障害、システム保守、故障等により利用者アプリ等または加盟店端末によるBank
                      Pay取引の取扱いができない場合
                    </li>
                    <li>
                      <ListMark>②</ListMark>
                      1回あたりのBank
                      Pay取引の金額が、BP加盟店が定めた最高限度額を超えまたは最低限度額に満たない場合
                    </li>
                    <li>
                      <ListMark>③</ListMark>
                      購入する商品または提供を受ける役務等が、当該BP加盟店においてBank
                      Pay取引によって行うことができないものと定めた商品または役務等に該当する場合
                    </li>
                    <li>
                      <ListMark>④</ListMark>
                      第1条第1項各号但書または第1条の2第1項但書の定めに該当する場合
                    </li>
                    <li>
                      <ListMark>⑤</ListMark>
                      1日あたりの登録預金口座の利用金額が、当行が定めた範囲を超える場合
                    </li>
                    <li>
                      <ListMark>⑥</ListMark>
                      当行所定の回数を超えて利用者アプリ等のパスワード等を誤って入力等した場合等、本人認証ができない場合
                    </li>
                    <li>
                      <ListMark>⑦</ListMark>
                      利用者アプリ等が機能していない場合
                    </li>
                    <li>
                      <ListMark>⑧</ListMark>
                      利用者端末の故障・破損により利用者アプリ等の使用が困難な場合
                    </li>
                    <li>
                      <ListMark>⑨</ListMark>
                      当行所定のBank
                      Pay取引を行うことができない日または時間帯であるとき
                    </li>
                    <li>
                      <ListMark>⑩</ListMark>
                      利用者アプリ等がBP加盟店の指定するものでないとき
                    </li>
                    <li>
                      <ListMark>⑪</ListMark>
                      登録預金口座からの預金の引落しが禁止または制限されているとき
                    </li>
                  </ListMemo03>
                </li>
                <li>
                  <ListNum>(5)</ListNum>
                  当行は、利用者によるBank
                  Pay取引の利用状況等を勘案して、必要に応じて利用者に対して登録預金口座にかかるキャッシュカードまたは通帳、本人確認書類の提示等を要求する場合があります。
                </li>
              </ListMemo02>
            </li>
            <li>
              <ListMark>4.</ListMark>
              （Bank Pay取引契約等）
            </li>
            <li>
              <ListMemo02>
                <li>
                  <ListNum>(1)</ListNum>
                  利用者が利用者アプリ等において本人認証を行い、かつ、Bank
                  Pay取引を実行した時に、加盟店端末への通知その他の機構所定の方法によりBP加盟店に口座引落確認を表す電文が通知されないことを解除条件として、BP加盟店との間で売買取引債務を登録預金口座からの預金の引落しによって支払う旨の契約（以下｢Bank
                  Pay取引契約｣といいます｡）が成立するものとします。
                </li>
                <li>
                  <ListNum>(2)</ListNum>
                  前項の規定にかかわらず、利用者が利用者アプリ等において本人認証を行い、かつ、BP加盟店との間において継続的に発生する売買取引債務を登録預金口座からの預金の引落しによって支払うことを約した場合には、売買取引債務の支払時期が到来する都度、BP加盟店より伝送される請求データに基づく登録預金口座からの引落しの時に、BP加盟店との間でBank
                  Pay取引契約が成立するものとみなします。
                </li>
                <li>
                  <ListNum>(3)</ListNum>
                  前二項の規定によりBank
                  Pay取引契約が成立したときは、その成立に先立って利用者によって次の行為がなされたものとみなします。
                </li>
                <li>
                  <ListMemo03>
                    <li>
                      <ListMark>①</ListMark>
                      当行に対する売買取引債務相当額の預金引落しの指図および当該指図に基づいて引き落された預金による売買取引債務の弁済の委託。なお、当該預金引落しの指図について、通帳および払戻請求書の提出は不要とします。
                    </li>
                    <li>
                      <ListMark>②</ListMark>
                      BP加盟店銀行、BP直接加盟店またはBP任意組合その他の機構所定の者（以下本条において「譲受人」と総称します。）に対する売買取引債務にかかる債権の譲渡に関して当該売買取引にかかる利用者の抗弁を放棄する旨の意思表示。なお、当行は、当該意思表示を、当該売買取引債権の譲受人に代わって受領します。
                    </li>
                  </ListMemo03>
                </li>
                <li>
                  <ListNum>(4)</ListNum>
                  前項の「抗弁を放棄する旨の意思表示」とは、利用者が売買取引に関してBP加盟店またはその特定承継人に対して主張しうる、売買取引の無効・取消し・解除、売買取引債務の弁済による消滅・同時履行・相殺、売買取引の不存在、売買取引債務の金額の相違、目的物の品質不良・引渡し未了、その他売買取引債務の履行を拒絶する旨の一切の主張を放棄することを意味します。
                </li>
              </ListMemo02>
            </li>
            <li>
              <ListMark>4の2.</ListMark>
              （立替払の場合の特則）
            </li>
            <li>
              <ListMemo02>
                <li>
                  <ListNum>(1)</ListNum>
                  立替払方式の場合は、利用者が利用者アプリ等において第3条第2項により本人認証を行い、かつ、Bank
                  Pay取引を実行した時に、加盟店端末への通知その他の機構所定の方法でBP加盟店に口座引落確認を表す電文が通知されないことを解除条件として、BP加盟店銀行が利用者に代わって売買取引債務を支払う旨の契約が利用者と当該BP加盟店との間で成立するものとし、この契約もBank
                  Pay取引契約に含めるものとします。また、この場合、当該BP加盟店銀行は自らまたはBP直接加盟店もしくはBP任意組合を通じて当該売買取引債務の立替払をするものとし、利用者は第1条第2項および第1条の2第1項に基づき当該BP加盟店銀行に対して負担する補償債務を、登録預金口座からの引落しによって支払うものとします。なお、預金引落しの指図については、通帳および払戻請求書の提出は不要です。
                </li>
                <li>
                  <ListNum>(2)</ListNum>
                  前項の規定にかかわらず、利用者が利用者アプリ等において本人認証を行い、かつ、BP加盟店との間において継続的に発生する売買取引債務をBP加盟店銀行が自らまたはBP直接加盟店もしくはBP任意組合を通じて立替払する場合には、売買取引債務の支払時期が到来する都度、BP加盟店より伝送される請求データに基づく登録預金口座からの引落しの時に、BP加盟店との間でBank
                  Pay取引契約が成立するものとみなします。
                </li>
                <li>
                  <ListNum>(3)</ListNum>
                  前二項の規定によりBank
                  Pay取引契約が成立したときは、売買取引の無効・取消し・解除、売買取引債務の弁済による消滅・同時履行・相殺、売買取引の不存在、売買取引債務の金額の相違、目的物の品質不良・引渡し未了、その他売買取引債務の履行を拒絶しうる旨の一切事由があったとしても、かかる事由をもってBP加盟店銀行、当行その他の者に対して異議を述べないものとします。
                </li>
                <li>
                  <ListNum>(4)</ListNum>
                  第1項および第2項に定めるBank
                  Pay取引契約が成立した場合、加盟店銀行又はBP直接加盟店もしくはBP任意組合は、規約に基づき、利用者がBP加盟店に対して負う売買取引債務につき、当該BP加盟店に対して立替払をする義務を負い、その時点で利用者の当該売買取引債務は消滅するものとします。但し、第1条の2に定めるBank
                  Pay取引契約の場合の利用者の売買取引債務は、第1項に基づき当該BP加盟店に対して立替払が実行された時点で消滅するものとします。
                </li>
              </ListMemo02>
            </li>
            <li>
              <ListMark>5.</ListMark>
              （Bank Pay取引時の認証）
            </li>
            <li>
              <ListMemo02>
                <li>
                  <ListNum>(1)</ListNum>
                  当行は、利用者アプリにより行われるBank Pay取引の際に、当該Bank
                  Pay取引が利用者本人によるものであることを、次の方法で確認します。
                </li>
                <li>
                  <ListMemo03>
                    <li>
                      <ListMark>①</ListMark>
                      Bank
                      Pay取引の操作等の際に入力等されたパスワード等が、あらかじめ利用者アプリにおいて設定されたパスワード等と一致することの確認（利用者アプリにおいて要求された場合に限ります。）
                    </li>
                    <li>
                      <ListMark>②</ListMark>
                      Bank
                      Pay取引の際に使用された端末が利用者アプリに利用者本人の利用者端末として登録された端末であることの、利用者アプリ所定の方法での確認
                    </li>
                  </ListMemo03>
                </li>
                <li>
                  <ListNum>(2)</ListNum>
                  当行は、Bank Pay取引サイトにおいて行われるBank
                  Pay取引の際に、当該Bank
                  Pay取引が利用者本人によるものであることを、当該Bank
                  Pay取引サイト所定の認証手続により確認します。
                </li>
                <li>
                  <ListNum>(3)</ListNum>
                  当行が前二項の規定により利用者本人によるBank
                  Pay取引であることを確認し、相違ないものと認めてその取扱いを行った場合には、それが偽造、変造、盗用、第三者によるなりすまし、その他の事故により利用者本人による取引でなかった場合でも、当行は当該取引を有効なものとして取り扱います。また、そのために生じた損害については、第9条に定める場合を除き、当行は一切責任を負いません。
                </li>
                <li>
                  <ListNum>(4)</ListNum>
                  第1項および第2項に定めるBank
                  Pay取引契約が成立した場合、加盟店銀行又はBP直接加盟店もしくはBP任意組合は、規約に基づき、利用者がBP加盟店に対して負う売買取引債務につき、当該BP加盟店に対して立替払をする義務を負い、その時点で利用者の当該売買取引債務は消滅するものとします。但し、第1条の2に定めるBank
                  Pay取引契約の場合の利用者の売買取引債務は、第1項に基づき当該BP加盟店に対して立替払が実行された時点で消滅するものとします。
                </li>
              </ListMemo02>
            </li>
            <li>
              <ListMark>6.</ListMark>
              （利用者アプリ等へのアクセス管理、パスワード等の設定・管理等）
            </li>
            <li>
              <ListMemo02>
                <li>
                  <ListNum>(1)</ListNum>
                  利用者アプリ等の使用に当たっては、当該利用者アプリ等所定の利用規約を遵守するとともに、他人により不正にアクセスされないように利用者アプリを管理してください。特に、パスワード等については、他人に使用されないよう管理するとともに、パスワード等に、氏名、住所、生年月日、電話番号、連続番号等の他人に推測されやすい番号や文字列を使用しないでください。
                </li>
                <li>
                  <ListNum>(2)</ListNum>
                  パスワード等の偽造、盗難、紛失その他の事由により、利用者アプリ等が他人に使用されるおそれが生じた場合または他人に使用されたことを認知した場合には、すみやかに利用者本人から利用者アプリ等の提供者または当行に通知し、利用者アプリ等を使用したBank
                  Pay取引を不能とする措置や口座の停止等の不正利用の拡大防止措置を講じてください。
                </li>
                <li>
                  <ListNum>(3)</ListNum>
                  前条第1項および第2項の場合のほか、利用者アプリ等の所定の操作に際して本人認証が要求され、これに応じた本人認証を経た結果、当該利用者アプリ等において当該操作が実行された場合には、当該操作は利用者本人によるものとみなします。当該操作が第三者による不正な操作であり、それによって利用者が損害を被った場合であっても、当行は、第9条に定める場合を除き、一切責任を負いません。
                </li>
              </ListMemo02>
            </li>
            <li>
              <ListMark>7.</ListMark>
              （預金の復元等）
            </li>
            <li>
              <ListMemo02>
                <li>
                  <ListNum>(1)</ListNum>
                  Bank
                  Pay取引により登録預金口座から預金の引落しがされた場合には、Bank
                  Pay取引契約が解除（合意解除を含みます｡）、取消し等により適法に解消されたとき（売買取引の解消とあわせてBank
                  Pay取引契約が解消された場合を含みます｡）であっても、BP加盟店以外の第三者（BP加盟店の特定承継人および当行を含みます｡）に対して引き落された預金相当額の金銭の支払いを請求する権利を有しないものとし、また当行に対して引き落された預金の復元を請求することもできないものとします。
                </li>
                <li>
                  <ListNum>(2)</ListNum>
                  前項の規定にかかわらず、Bank
                  Pay取引を行ったBP加盟店に利用者端末およびBP加盟店が必要と認める本人確認資料等を持参して、引き落された預金の復元をBP加盟店経由で請求し、これを受けたBP加盟店が、所定の方法で当行に対して取消しの電文を送信し、当行が当該電文をBank
                  Pay取引契約が成立した当日中に受信した場合に限り、当行は引き落された預金の復元をします。加盟店端末から取消しの電文を送信することができないときは、引き落された預金の復元はできません。
                </li>
                <li>
                  <ListNum>(3)</ListNum>
                  前二項の規定により引き落された預金の復元等ができないときは、BP加盟店から現金により返金を受ける等、BP加盟店との間で解決してください。
                </li>
                <li>
                  <ListNum>(4)</ListNum>
                  Bank
                  Pay取引において金額等の誤入力があったにもかかわらずこれを看過してBank
                  Pay取引契約が成立した場合についても、前三項の規定に準じて取り扱うものとします。
                </li>
              </ListMemo02>
            </li>
            <li>
              <ListMark>8.</ListMark>
              （利用者の責任）
            </li>
            <li>
              <ListMemo02>
                <li>
                  <ListNum>(1)</ListNum>
                  利用者は、自らの責任でBank Pay取引を利用するものとし、Bank
                  Pay取引に関するすべての行為およびその結果について一切の責任を負うものとします。
                </li>
                <li>
                  <ListNum>(2)</ListNum>
                  利用者は、Bank
                  Pay取引を利用したことに起因して、当行が直接または間接に何らかの損害を被った場合（当行が第三者からクレームを受け、これに対応した場合を含みます。）、当行の請求に従って直ちにこれを補償するものとします。
                </li>
                <li>
                  <ListNum>(3)</ListNum>
                  利用者は、Bank
                  Pay取引を安全にご利用いただくため、次の事項を遵守するものとします。
                </li>
                <li>
                  <ListMemo03>
                    <li>
                      <ListMark>①</ListMark>
                      利用者端末を善良な管理者の注意をもって保管する等、利用者アプリを第三者が使用することのないように適切に管理すること
                    </li>
                    <li>
                      <ListMark>②</ListMark>
                      利用者アプリ等に登録したパスワード等その他の自らの情報を厳重に管理すること
                    </li>
                    <li>
                      <ListMark>③</ListMark>
                      利用者アプリのバージョンおよび利用者の使用にかかる通信端末のOS、ブラウザ等を常に最新の状態に保つとともに、当該通信端末がコンピュータウイルスへの感染や不正プログラムの攻撃を受けないよう、合理的に可能なセキュリティ対策のための措置を講じること
                    </li>
                    <li>
                      <ListMark>④</ListMark>
                      利用者アプリを使用する場合において、機種変更等の事由により利用者端末を変更する場合や、利用者端末を処分する場合には、使用しなくなった利用者端末からの利用者アプリのアンインストールその他の利用者アプリ所定の手続をするとともに、新たに使用することになる利用者端末によりBank
                      Pay取引を利用できるようにするために必要な利用者アプリ所定の手続を行うこと
                    </li>
                    <li>
                      <ListMark>⑤</ListMark>
                      利用者端末を紛失した場合、盗難等の被害を受けた場合その他の事由により、不正なBank
                      Pay取引の被害に遭うおそれがある場合には、直ちに当該利用者端末における通信サービスを提供する事業者に対して当該利用者端末による通信を不能にするための届出を行うとともに、当行および利用者アプリの提供者に連絡し、Bank
                      Pay取引の利用停止または登録預金口座の利用停止手続を行うこと
                    </li>
                  </ListMemo03>
                </li>
              </ListMemo02>
            </li>
            <li>
              <ListMark>9.</ListMark>
              （利用者端末の盗用等による損害等）
            </li>
            <li>
              <ListMemo02>
                <li>
                  <ListNum>(1)</ListNum>
                  利用者以外の第三者により不正に利用者名義の預金口座が登録預金口座として利用登録されたこと、または、利用者が利用者端末の紛失もしくは盗難（以下「盗難等」といいます。）にあったこと等により、第三者によって不正に行われたBank
                  Pay取引（以下「不正利用」といいます。）については、次の各号のすべてに該当する場合に限り、利用者は当行に対して当該不正利用にかかる損害（手数料や利息を含みます。）の額に相当する金額の補てんを請求することができます。但し、次条に規定する場合は、この限りではありません。
                </li>
                <li>
                  <ListMemo03>
                    <li>
                      <ListMark>①</ListMark>
                      利用者端末の盗難等に気付いたとき（利用者以外の第三者により不正に利用者名義の預金口座が登録預金口座として利用登録された場合にあっては、不正利用されたことに気づいたとき）に、直ちに当行への通知が行われていること
                    </li>
                    <li>
                      <ListMark>②</ListMark>
                      当行の調査に対し、利用者より十分な説明が行われていること
                    </li>
                    <li>
                      <ListMark>③</ListMark>
                      当行に対し、警察署に被害届を提出していることその他の不正利用にあったことが推測される事実を確認できるものを示していること
                    </li>
                  </ListMemo03>
                </li>
                <li>
                  <ListNum>(2)</ListNum>
                  前項の請求がなされた場合には、当該不正利用が利用者の故意による場合を除き、当行は、当行への通知が行われた日の30日（当行に通知することができないやむを得ない事情があることを本人が証明した場合は、30日にその事情が継続している期間を超えた日数）前の日以降になされた不正利用にかかる損害（手数料や利息を含みます。）の額に相当する金額（以下「補てん対象額」といいます。）を補てんするものとします。但し、当該不正利用が行われたことについて、当行が善意かつ無過失であり、かつ、利用者に過失があることを当行が証明した場合には、当行は補てん対象額の4分の3に相当する金額を補てんするものとします。
                </li>
                <li>
                  <ListNum>(3)</ListNum>
                  前二項の規定は、第1項にかかる当行への通知が、利用者以外の第三者により不正に利用者名義の預金口座が登録預金口座として利用登録された場合における不正利用が最初に行われた日または利用者端末の盗難等があった日（当該盗難等があった日が明らかでないときは、当該盗難等にかかる利用者端末を用いた不正利用が最初に行われた日）から、2年を経過する日より後に行われた場合には、適用されないものとします。
                </li>
                <li>
                  <ListNum>(4)</ListNum>
                  第2項の規定にかかわらず、次のいずれかに該当する場合には、当行は補てんを行いません。
                </li>
                <li>
                  <ListMemo03>
                    <li>
                      <ListMark>①</ListMark>
                      当該Bank
                      Pay取引が行われたことについて当行が善意かつ無過失であり、かつ、次のいずれかに該当する場合
                    </li>
                    <li>
                      <ListMemo03>
                        <li>
                          <ListMark>ア</ListMark>
                          利用者に重大な過失がある場合
                        </li>
                        <li>
                          <ListMark>イ</ListMark>
                          利用者の配偶者、二親等内の親族、同居の家族、その他の同居人または家事使用人（家事全般を行っている家政婦等）もしくは留守人によって行われまたは加担された場合
                        </li>
                        <li>
                          <ListMark>ウ</ListMark>
                          利用者が、被害状況についての当行に対する説明において、重要な事項について偽りの説明を行った場合
                        </li>
                        <li>
                          <ListMark>エ</ListMark>
                          利用者が本規定または本件関連規定に違反した場合
                        </li>
                      </ListMemo03>
                    </li>
                    <li>
                      <ListMark>②</ListMark>
                      戦争、暴動等による著しい社会秩序の混乱に乗じまたはこれに付随して利用者端末の盗難等にあった場合
                    </li>
                  </ListMemo03>
                </li>
                <li>
                  <ListNum>(5)</ListNum>
                  前項までの規定の適用は、個人である利用者に限るものとします。
                </li>
              </ListMemo02>
            </li>
            <li>
              <ListMark>
                10.（利用者アプリ等の提供者に対する補償請求等）
                <br />
                前条の規定にかかわらず、不正利用が機構所定の仕様によるQRコード等を利用したBank
                Pay取引以外のものにより生じた場合には、当該不正利用の発生により利用者に生じた損害の補償については、当該利用者アプリ等の提供者との間で解決してください。なお、この場合であっても、不正利用が発生したことについて当行に連絡をしてください。
              </ListMark>
            </li>
            <li>
              <ListMark>
                11.（Bank Pay取引の利用金額の通帳記入）
                <br />
                Bank Pay取引の利用に関する通帳記入は、当行所定の方法で行います。
              </ListMark>
            </li>
            <li>
              <ListMark>12.（Bank Pay取引の取扱停止等）</ListMark>
            </li>
            <li>
              <ListMemo02>
                <li>
                  <ListNum>(1)</ListNum>
                  利用者は、当行所定の方法により当行に通知することにより、いつでもBank
                  Pay取引の利用を停止し、または本契約を解除することができるものとします。
                </li>
                <li>
                  <ListNum>(2)</ListNum>
                  当行は、天災、事変、その他の非常事態が発生し、または発生するおそれのある場合には、事前に公表または利用者に通知することなく、Bank
                  Pay取引の取扱いの全部または一部の提供を停止する措置を講じることができるものとします。
                </li>
                <li>
                  <ListNum>(3)</ListNum>
                  当行は、Bank
                  Pay取引に関するシステム保守等の維持管理またはセキュリティの維持に必要な対応を行うため、当行またはBank
                  Pay取引に関する基幹システムを提供する者の判断により、Bank
                  Pay取引の全部または一部の取扱いを停止することができるものとします。この場合には、緊急を要する場合を除き、利用者に対して事前に当行ホームページ等で公表するものとします。
                </li>
                <li>
                  <ListNum>(4)</ListNum>
                  当行は、利用者が次の各号のいずれかに該当した場合には、直ちに利用者による利用者アプリの使用を停止もしくは廃止し、または本契約を解除することができるものとします。
                </li>
                <li>
                  <ListMemo03>
                    <li>
                      <ListMark>①</ListMark>
                      利用者が本規定もしくは本件関連規定または利用者アプリ所定の利用規約に違反したときまたはそのおそれのあるとき
                    </li>
                    <li>
                      <ListMark>②</ListMark>
                      利用者が利用者アプリの使用に際して当行に虚偽の情報を提供したとき
                    </li>
                    <li>
                      <ListMark>③</ListMark>
                      差押え、支払の停止、破産手続または再生手続の開始の申立て等、利用者の信用状態が著しく悪化したとき
                    </li>
                    <li>
                      <ListMark>④</ListMark>
                      利用者が換金目的でBank Pay取引を利用したとき
                    </li>
                    <li>
                      <ListMark>⑤</ListMark>
                      利用者がBank
                      Pay取引を不正な資金洗浄、テロ資金供与その他の法令で禁止される不正な取引等に利用しているときまたはそのおそれがあるとき
                    </li>
                    <li>
                      <ListMark>⑥</ListMark>
                      その他、利用者によるBank
                      Pay取引の利用状況が適当でないと当行が判断したとき
                    </li>
                  </ListMemo03>
                </li>
                <li>
                  <ListNum>(5)</ListNum>
                  当行は、前四項の規定によるBank
                  Pay取引の利用もしくは取扱いの停止、利用者アプリの使用停止もしくは廃止または本契約の解除に起因して利用者に生じた損害について、当行の責めに帰すべき事由による場合を除き、一切責任を負わないものとします。
                </li>
              </ListMemo02>
            </li>
            <li>
              <ListMark>13.（規定の変更）</ListMark>
            </li>
            <li>
              <ListMemo02>
                <li>
                  <ListNum>(1)</ListNum>
                  当行は、本規定の変更を必要とする相当の事由があると判断した場合には、当行ホームページへの掲載その他の当行が相当と認める方法により変更内容を公表することにより本規定を変更できるものとし、変更後の本規定は公表の際に定める1週間以上の期間を経過した日から適用されるものとします。利用者は、公表された変更内容に同意しない場合には、当行が公表の際に定める1週間以上の期間内にその旨を当行に通知するものとします。当該期間内に当行が利用者から変更に同意しない旨の通知を受領しなかった場合または当該期間の経過後に利用者がBank
                  Pay取引を利用した場合には、利用者は変更に同意したものとみなします。また、当行が利用者から変更に同意しない旨の通知を受領した場合には、当行は、事前に通知することなく、本契約を解除することができるものとします。
                </li>
                <li>
                  <ListNum>(2)</ListNum>
                  当行は、法令の定めにより本規定を変更できる場合には、当該法令に定める手続により本規定を変更することができるものとします。
                </li>
              </ListMemo02>
            </li>
          </ListMemo01>
          <FooterRight>
            以上
            <br />
            （2019年10月31日制定）
            <br />
            （2022年6月1日改定）
          </FooterRight>
        </section>
      </MainArea>
    </DivWrap>
  )
}

const DivWrap = styled.div`
  position: relative;
  margin: 0 auto 20px;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent;
  display: block;
  word-break: break-all;
  font: 14px Helvetica, Arial, Tahoma, sans-serif;
  font-family: 'Meiryo', 'メイリオ', 'ヒラギノ角ゴ Pro W3',
    'Hiragino Kaku Gothic Pro', Verdana, 'ＭＳ Ｐゴシック', sans-serif;
  line-height: 1.5;
  color: #333;
  -webkit-text-size-adjust: none;
`

const MainArea = styled.main`
  padding: 0 10px 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
`

const H1Title = styled.h1`
  position: relative;
  margin: 0 -10px 20px;
  font-size: 100%;

  span {
    display: inline-block;
    position: relative;
    line-height: 1.1;
    width: 100%;
    padding: 10px;
    border-bottom: solid 1px #e2e2e2;
    -ms-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 2.143em;
    font-weight: bold;
    color: #004831;
  }

  @media screen and (max-width: 767px) {
    span {
      width: 100%;
      padding: 16px 10px 10px;
      border-bottom: solid 1px #e2e2e2;
      box-sizing: border-box;
      font-size: 1.43em;
      font-weight: bold;
      color: #004831;
    }
  }
`

const ListMemo01 = styled.ul`
  font-size: 0.857em;
  margin-bottom: 20px;

  li {
    padding-left: 1.4em;
    text-indent: -1.4em;
    margin-bottom: 10px;
    list-style-type: none;
  }
`

const ListMark = styled.span`
  padding-right: 5px;
`

const ListMemo02 = styled.ul`
  padding-left: 1.5em;
  margin-bottom: 0;

  li {
    padding-left: 2.5em;
    position: relative;
    text-indent: 0;
    margin-bottom: 10px;
    list-style-type: none;
  }
`

const ListNum = styled.span`
  display: inline-block;
  min-width: 21px;
  position: absolute;
  top: 0;
  left: 0;
`
const ListMemo03 = styled.ul`
  padding-left: 1.5em;
  margin-bottom: 0;
  font-size: 1em;
  line-height: 1.57;

  li {
    padding-left: 1.4em;
    text-indent: -1.4em;
    margin-bottom: 10px;
    list-style-type: none;
  }
`

const FooterRight = styled.p`
  text-align: right;
  font-size: 0.857em;
  line-height: 1.5;
`
